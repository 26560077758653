// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Principles, Menu, Impressum } from './container';
import { Navbar, CookieBanner } from './components';
import './App.css';

const App = () => (
  <Router>
    <div className="app-container"> {}
      <Navbar />
      <CookieBanner />
      <Routes>
        <Route
          path="/"
          element={(
            <div>
              <Header />
              <AboutUs />
              <Chef />
              <Menu />
              <Principles />
              <Gallery />
              <FindUs />
            </div>
)}
        />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>
      <Footer />
    </div>
  </Router>
);

export default App;
