import React, { useEffect, useRef, useState } from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Chef.css";

const Chef = () => {
  const imageRef = useRef(null);
  const [hasAnimatedImage, setHasAnimatedImage] = useState(false);

  const infoRef = useRef(null);
  const [hasAnimatedInfo, setHasAnimatedInfo] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 0;
            entry.target.style.animation = "fadeIn 2s ease-in-out forwards";
            entry.target.addEventListener("animationstart", () => {
              entry.target.style.opacity = 0;
            });
            entry.target.addEventListener("animationend", () => {
              entry.target.style.opacity = 1;
            });
            if (entry.target === imageRef.current) {
              setHasAnimatedImage(true);
            }else if (entry.target === infoRef.current) {
              setHasAnimatedInfo(true);
            }
            
          } else if (!hasAnimatedImage && entry.target === imageRef.current) {
            entry.target.style.animation = "none";
          }  else if (
            !hasAnimatedInfo &&
            entry.target === infoRef.current
          ) {
            entry.target.style.animation = "none";
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const imageNode = imageRef.current;
    const infoNode = infoRef.current;

    if (imageNode) {
      observer.observe(imageNode);
    }

    if (infoNode) {
      observer.observe(infoNode);
    }

    return () => {
      if (imageNode) {
        observer.unobserve(imageNode);
      }
      if (infoNode) {
        observer.unobserve(infoNode);
      }
    };
  }, [hasAnimatedImage, hasAnimatedInfo]);

  return (
    <div className="app__bg app__wrapper section__padding">
      <div className="app__wrapper_img app__wrapper_img-reverse" ref={imageRef}>
        <img src={images.chefi} alt="chef_image" />
      </div>
      <div className="app__wrapper_info" ref={infoRef}>
        <SubHeading title="Motivation" />
        <h1 className="headtext__cormorant">Wofür wir stehen</h1>
        <div className="app__chef-content">
          <div className="app__chef-content_quote">
            <p className="p__opensans">
              Bei twenty8 bieten wir eine vielfältige Auswahl an hochwertigen
              Burgern, die Tradition und Innovation vereinen. Wir sind stolz
              darauf, sowohl klassische Fleischburger als auch vegane und
              vegetarische Optionen anzubieten. Unser Ziel ist es, jedem Gast
              ein unvergleichliches Geschmackserlebnis zu bieten, unabhängig von
              seinen Ernährungspräferenzen. Entdecken Sie die Vielfalt unserer
              Burger, bei denen Qualität und Genuss im Mittelpunkt stehen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chef;
