import React, { useEffect, useRef, useState } from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';

const Header = () => {
  const headerRef = useRef(null);
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 0;
            entry.target.style.animation = "fadeIn 2s ease-in-out forwards";
            entry.target.addEventListener("animationstart", () => {
              entry.target.style.opacity = 0;
            });
            entry.target.addEventListener("animationend", () => {
              entry.target.style.opacity = 1;
            });
            if (entry.target === headerRef.current) {
              setHasAnimated(true);
            }
          } else if (!hasAnimated && entry.target === headerRef.current) {
            entry.target.style.animation = "none";
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const headerNode = headerRef.current;

    if (headerNode) {
      observer.observe(headerNode);
    }

    return () => {
      if (headerNode) {
        observer.unobserve(headerNode);
      }
    };
  }, [hasAnimated]);

  return (
    <div className="app__header app__wrapper section__padding" id="home" ref={headerRef}>
      <div className="app__wrapper_info">
        <SubHeading title="Gönne dir den besten Burger" />
        <h1 className="app__header-h1">Entdecke den Burger der Extraklasse</h1>
        <p className="p__opensans" style={{ margin: '2rem 0' }}>Unsere Burger werden frisch zubereitet und mit den besten Zutaten hergestellt. Komme vorbei und genieße das unvergleichliche Geschmackserlebnis.</p>
        <a href="#menu" className="custom__button">Zur Speisekarte</a>
      </div>

      <div className="app__wrapper_img">
        <img src={images.header} alt="header_img" style={{width: '100%', height: 'auto'}} />
      </div>
    </div>
  );
};

export default Header;
