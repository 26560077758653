import React, { useState } from 'react';
import CookieConsent from "react-cookie-consent";

export default function CookieBanner() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleAccept = () => {
  };

  const handleDecline = () => {
  };

  const buttonStyle = {
    backgroundColor: isHovered ? "#DCCA87" : "#F5EFDB",
    color: "black",
    fontFamily: "'Bebas Neue', serif;",
    fontWeight: "700",
    letterSpacing: "0.04em",
    lineHeight: "28px",
    fontSize: "16px",
    padding: "0.5rem 1.5rem",
    borderRadius: "1px",
    border: "none",
    outline: "none",
    cursor: "pointer",
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Akzeptieren"
      cookieName="cookieName"
      onAccept={handleAccept}
      enableDeclineButton
      declineButtonText="Ablehnen"
      onDecline={handleDecline}
      declineCookieValue={false}
      style={{ background: "black" }}
      buttonStyle={buttonStyle}
      declineButtonStyle={buttonStyle}
      expires={150}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <p className="p__opensans">Diese Website verwendet Cookies, um das Nutzererlebnis zu verbessern. </p>
    </CookieConsent>
  );
}
