import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./AboutUs.css";
import { SubHeading } from "../../components";

const AboutUs = () => {
  const aboutRef = useRef(null);
  const [hasAnimatedAbout, setHasAnimatedAbout] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === aboutRef.current) {
              setHasAnimatedAbout(true);
            }
          }
        });
      },
      {
        threshold: 0.3,
        rootMargin: '200px'
      }
    );

    const aboutNode = aboutRef.current;

    if (aboutNode) {
      observer.observe(aboutNode);
    }

    return () => {
      if (aboutNode) {
        observer.unobserve(aboutNode);
      }
    };
  }, []);

  return (
    <div
      className="app__aboutus app__bg flex__center section__padding"
      id="about"
    >
      <div className="app__aboutus-content flex__center">
        <CSSTransition
          in={hasAnimatedAbout}
          timeout={2000}
          classNames="fade"
        >
          <div className="app__aboutus-content_about" ref={aboutRef}>
            <SubHeading title="Über Uns" />
            <h1 className="headtext__cormorant">Das Macht Uns Aus</h1>
            <p className="p__opensans">
            Als wir mit Twenty8 begannen, wussten wir, dass wir ein besonderes
            Konzept bieten mussten, um uns von anderen Burgerläden abzuheben.
            Wir haben hart daran gearbeitet, die perfekte Mischung aus
            Geschmack, Qualität und Preis-Leistungs-Verhältnis zu finden. <br />
            <br /> Wir haben viele Stunden damit verbracht, unsere Burgerrezepte
            zu verfeinern und sicherzustellen, dass jedes Detail perfekt ist,
            vom Brötchen bis zum Belag. Die harte Arbeit hat sich ausgezahlt,
            als wir unsere Türen zum ersten Mal öffneten und die Leute unsere
            Burger und unsere Atmosphäre liebten. Seitdem sind wir gewachsen und
            haben viele loyale Kunden gewonnen, die immer wieder zu uns
            zurückkehren.
            </p>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default AboutUs;
