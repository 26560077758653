import React from 'react';
import './SubHeading.css';
import { images } from '../../constants';

const SubHeading = ({ title }) => (
  <div className="subheading">
    <p className="p__cormorant">{title}</p>
    <img src={images.spatula} alt="spoon_image" className="spatula__img" />
  </div>
);

export default SubHeading;
