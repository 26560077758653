import React, { useEffect, useRef, useState } from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

import './Contact.css';

const openGoogleMaps = () => {
  const url = 'https://www.google.com/maps/place/twenty8+burger/@49.2941397,10.5740296,17z/data=!3m1!4b1!4m6!3m5!1s0x4798bb94a437c18f:0xec648b0488e37b18!8m2!3d49.2941362!4d10.5766045!16s%2Fg%2F11sdfz1kzw';
  window.open(url, '_blank');
};

const FindUs = () => {
  const footerRef = useRef(null);
  const [hasAnimatedFooter, setHasAnimatedFooter] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 0;
            entry.target.style.animation = "fadeIn 2s ease-in-out forwards";
            entry.target.addEventListener("animationstart", () => {
              entry.target.style.opacity = 0;
            });
            entry.target.addEventListener("animationend", () => {
              entry.target.style.opacity = 1;
            });
            if (entry.target === footerRef.current) {
              setHasAnimatedFooter(true);
            }
          } else if (!hasAnimatedFooter && entry.target === footerRef.current) {
            entry.target.style.animation = "none";
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const footerNode = footerRef.current;

    if (footerNode) {
      observer.observe(footerNode);
    }

    return () => {
      if (footerNode) {
        observer.unobserve(footerNode);
      }
    };
  }, [hasAnimatedFooter]);
  return (
  <div className="app__bg app__wrapper section__padding" id="contact" ref={footerRef}>
    <div className="app__wrapper_info">
      <SubHeading title="Kontakt" />
      <h1 className="headtext__cormorant" style={{ marginBottom: '3rem' }}>Anfahrt</h1>
      <div className="app__wrapper-content">
      <p className="p__opensans">+49 981 977 705 15</p>
        <p className="p__opensans">Bandelstraße 28</p>
        <p className="p__opensans">91522 Ansbach</p>
        <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Öffnungszeiten</p>
        <p className="p__opensans">Täglich von 11:30 - 14:30 & 16:30 - 21:30</p>
        <p className="p__opensans">Dienstag ist Ruhetag</p>
      </div>
      <button type="button" className="custom__button" style={{ marginTop: '2rem' }} onClick={openGoogleMaps}>Besuche uns</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.contact} alt="finus_img" style={{width: '100%', height: 'auto'}} />
    </div>
  </div>
);
  };

export default FindUs;
