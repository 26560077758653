import React, { useEffect, useRef, useState } from "react";

import { SubHeading } from "../../components";
import { images, data } from "../../constants";
import "./Principles.css";

const AwardCard = ({ award: { title, subtitle } }) => (
  <div className="app__principles_awards-card">
    <div className="app__principles_awards-card_bullet" />
    <div className="app__principles_awards-card_content">
      <p className="p__cormorant" style={{ color: "#DCCA87" }}>
        {title}
      </p>
      <p className="p__opensans">{subtitle}</p>
    </div>
  </div>
);

const Principles = () => {
  const footerRef = useRef(null);
  const [hasAnimatedFooter, setHasAnimatedFooter] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 0;
            entry.target.style.animation = "fadeIn 2s ease-in-out forwards";
            entry.target.addEventListener("animationstart", () => {
              entry.target.style.opacity = 0;
            });
            entry.target.addEventListener("animationend", () => {
              entry.target.style.opacity = 1;
            });
            if (entry.target === footerRef.current) {
              setHasAnimatedFooter(true);
            }
          } else if (!hasAnimatedFooter && entry.target === footerRef.current) {
            entry.target.style.animation = "none";
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const footerNode = footerRef.current;

    if (footerNode) {
      observer.observe(footerNode);
    }

    return () => {
      if (footerNode) {
        observer.unobserve(footerNode);
      }
    };
  }, [hasAnimatedFooter]);
  return (
    <div
      className="app__bg app__wrapper section__padding"
      id="awards"
      ref={footerRef}
    >
      <div className="app__wrapper_info">
        <SubHeading title="Was uns auszeichnet" />
        <h1 className="headtext__cormorant">Unsere Prinzipien</h1>

        <div className="app__principles_awards">
          {data.awards.map((award) => (
            <AwardCard award={award} key={award.title} />
          ))}
        </div>
      </div>

      <div className="app__wrapper_img">
        <img src={images.nice} alt="principles_img" />
      </div>
    </div>
  );
};

export default Principles;
