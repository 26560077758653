import React, { useEffect, useRef, useState } from 'react';
import { FiFacebook, FiInstagram } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => {
  const footerRef = useRef(null);
  const [hasAnimatedFooter, setHasAnimatedFooter] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 0;
            entry.target.style.animation = "fadeIn 2s ease-in-out forwards";
            entry.target.addEventListener("animationstart", () => {
              entry.target.style.opacity = 0;
            });
            entry.target.addEventListener("animationend", () => {
              entry.target.style.opacity = 1;
            });
            if (entry.target === footerRef.current) {
              setHasAnimatedFooter(true);
            }
          } else if (!hasAnimatedFooter && entry.target === footerRef.current) {
            entry.target.style.animation = "none";
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const footerNode = footerRef.current;

    if (footerNode) {
      observer.observe(footerNode);
    }

    return () => {
      if (footerNode) {
        observer.unobserve(footerNode);
      }
    };
  }, [hasAnimatedFooter]);

  return (
    <div className="app__footer section__padding" id="login" ref={footerRef}>
      <div className="app__footer-links">
        <div className="app__footer-links_logo">
          <img src={images.logo} alt="footer_logo" />
          <div className="app__footer-links_icons">
            <a href="https://www.facebook.com/twenty8ansbach/" target="_blank" rel="noopener noreferrer">
              <FiFacebook />
            </a>
            <a href="https://www.instagram.com/twenty8burger" target="_blank" rel="noopener noreferrer">
              <FiInstagram />
            </a>
          </div>
        </div>
      </div>

      <div className="app__footer-links_impressum">
        <Link to="/impressum" className="p__opensans app__footer-links_impressum-link">
          IMPRESSUM
        </Link>
      </div>

      <div className="footer__copyright">
        <p className="p__opensans">2023 twenty8. Alle Rechte vorbehalten.</p>
      </div>

    </div>
  );
};

export default Footer;
