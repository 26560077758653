import React from "react";

import { SubHeading, MenuItem } from "../../components";
import { data, images } from "../../constants";
import "./Menu.css";

const Menu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-inner">
      <div className="app__specialMenu-title">
        <SubHeading title="Verliebe dich in den Geschmack - Bei uns gibt's den besten Burger!" />
        <h1 className="headtext__cormorant">Speisekarte</h1>
        <p className="p__opensans">
          Alle Beilagen werden mit Ketchup und Mayo serviert.
          <br />
          Du magst kein Brötchen zu deinem Burger? Kein Problem, alle Burger
          können mit einer Salatbeilage bestellt werden. Brotlos!
          <br />
          Unsere Burger werden mit Fleisch und Bröttchen aus der Region und
          frischem Salat zubereitet.
          <br />
          Möchtest du aber lieber eingelegte Gewürzgurken, auch kein Problem,
          einfach umbestellen.
        </p>
      </div>
      <div className="imageContainer">
              <img src={images.menuburger} alt="menu_menuburger"></img>
            </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_burgers  flex__center">
          <p className="app__specialMenu-menu_heading">Burger</p>
          <div className="app__specialMenu_menu_items">
            {data.burgers.map((burger, index) => (
              <MenuItem
                key={burger.title + index}
                title={burger.title}
                price={burger.price}
                tags={burger.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_burgers  flex__center">
          <p className="app__specialMenu-menu_heading">Vegan & Vegetarisch</p>
          <div className="app__specialMenu_menu_items">
            {data.veganAlternatives.map((vegan, index) => (
              <MenuItem
                key={vegan.title + index}
                title={vegan.title}
                price={vegan.price}
                tags={vegan.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="imageContainer">
      <img src={images.topping} alt="menu_topping"></img>
            </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Toppings</p>
          <div className="app__specialMenu_menu_items">
            {data.toppings.map((topping, index) => (
              <MenuItem
                key={topping.title + index}
                title={topping.title}
                price={topping.price}
                tags={topping.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Beilagen</p>
          <div className="app__specialMenu_menu_items">
            {data.sideDishes.map((sideDish, index) => (
              <MenuItem
                key={sideDish.title + index}
                title={sideDish.title}
                price={sideDish.price}
                tags={sideDish.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Saucen & DIPS</p>
          <div className="app__specialMenu_menu_items">
            {data.saucesAndDips.map((sauce, index) => (
              <MenuItem
                key={sauce.title + index}
                title={sauce.title}
                price={sauce.price}
                tags={sauce.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Salate</p>
          <div className="app__specialMenu_menu_items">
            {data.salads.map((salad, index) => (
              <MenuItem
                key={salad.title + index}
                title={salad.title}
                price={salad.price}
                tags={salad.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="imageContainer">
      <img src={images.cocktails} alt="menu_cocktails"></img>
            </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Durstlöscher</p>
          <div className="app__specialMenu_menu_items">
            {data.thirstQuencher.map((thirstQuencher, index) => (
              <MenuItem
                key={thirstQuencher.title + index}
                title={thirstQuencher.title}
                price={thirstQuencher.price}
                tags={thirstQuencher.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Hausgemacht</p>
          <div className="app__specialMenu_menu_items">
            {data.homemade.map((homemade, index) => (
              <MenuItem
                key={homemade.title + index}
                title={homemade.title}
                price={homemade.price}
                tags={homemade.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Biere</p>
          <div className="app__specialMenu_menu_items">
            {data.beer.map((beer, index) => (
              <MenuItem
                key={beer.title + index}
                title={beer.title}
                price={beer.price}
                tags={beer.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Spritzig</p>
          <div className="app__specialMenu_menu_items">
            {data.sparkling.map((sparkling, index) => (
              <MenuItem
                key={sparkling.title + index}
                title={sparkling.title}
                price={sparkling.price}
                tags={sparkling.tags}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="app__specialMenu-menu">
        <div className="app__specialMenu-menu_cocktails  flex__center">
          <p className="app__specialMenu-menu_heading">Für die Seele</p>
          <div className="app__specialMenu_menu_items">
            {data.dessert.map((dessert, index) => (
              <MenuItem
                key={dessert.title + index}
                title={dessert.title}
                price={dessert.price}
                tags={dessert.tags}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="app__specialMenu-footnote">
      <p className="p__opensans">
        Alle Preise verstehen sich inklusive Mehrwertsteuer. Wegen Allergenen
        und Zusatzstoffen fragen Sie bitte unsere Mitarbeiter.
      </p>
    </div>
  </div>
);

export default Menu;
