import React, { useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { images } from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const targetElement = document.querySelector(location.hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [location.hash]);

  return (
    <>
      <div className="app__logo-and-button-wrapper">
        <div className="app__logo-wrapper">
          <Link to="/">
            <div className="app__navbar-logo">
              <img src={images.logo} alt="app__logo" />
            </div>
          </Link>
        </div>
        {/* <span
          className="order-button"
          data-glf-cuid="6269a1db-6791-4832-91b5-c6343e638e37"
          data-glf-ruid="09245668-c10f-4134-b7b9-5e476fa82fec"
        >
          BESTELLEN
        </span> */}
      </div>

      <nav className="app__navbar">
        <ul className="app__navbar-links">
          <li className="p__opensans">
            <Link to="/#home">Home</Link>
          </li>
          <li className="p__opensans">
            <Link to="/#about">Über uns</Link>
          </li>
          <li className="p__opensans">
            <Link to="/#menu">Speisekarte</Link>
          </li>
          <li className="p__opensans">
            <Link to="/#contact">Kontakt</Link>
          </li>
          <li className="p__opensans">
            <Link to="/impressum">Impressum</Link>
          </li>
        </ul>
        <div className="app__navbar-smallscreen">
          <GiHamburgerMenu
            color="#fff"
            fontSize={27}
            onClick={() => setToggleMenu(true)}
          />
          {toggleMenu && (
            <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
              <MdOutlineRestaurantMenu
                fontSize={27}
                className="overlay__close"
                onClick={() => setToggleMenu(false)}
              />
              <ul className="app__navbar-smallscreen_links">
                <li>
                  <Link to="/#home" onClick={() => setToggleMenu(false)}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/#about" onClick={() => setToggleMenu(false)}>
                    Über uns
                  </Link>
                </li>
                <li>
                  <Link to="/#menu" onClick={() => setToggleMenu(false)}>
                    Speisekarte
                  </Link>
                </li>
                <li>
                  <Link to="/#contact" onClick={() => setToggleMenu(false)}>
                    Kontakt
                  </Link>
                </li>
                <li>
                  <Link to="/impressum" onClick={() => setToggleMenu(false)}>Impressum</Link>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;