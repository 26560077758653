import { images } from './images';

const burgers = [
  {
    title: 'The Classic Hamburger',
    price: '',
    tags: '180 g homemade Beef Patty | Salat | Gurke | rote Zwiebeln | Haussauce',
  },
  {
    title: 'The American Cheeseburger',
    price: '',
    tags: '180 g homemade Beef Patty | Cheddar | Salat | Gurke | rote Zwiebeln | Haussauce',
  },
  {
    title: 'BIG BABO "BBQ Burger"',
    price: '',
    tags: '180 g homemade Beef Patty | Turkey Bacon | Cheddar | Salat | Gurke | Tomate | rote Zwiebeln | BBQ-Sauce',
  },
  {
    title: 'Cheese Star Burger (spicy/not spycy)',
    price: '',
    tags: '180 g homemade Beef Patty | Chili Cheese-Jalapeños-Sauce | Rucola Salat | Gurke | Tomate | rote Zwiebeln | Haussauce',
  },
  {
    title: 'Egg Tasty Burger',
    price: '',
    tags: '180 g homemade Beef Patty | Cheddar | Bio Spiegelei | Rucola Salat | Gurke | rote Zwiebeln | Haussauce',
  },
  {
    title: 'Crispy Chicken Burger',
    price: '',
    tags: 'Crispy Chicken Patty | Salat | Gurke | rote Zwiebeln | Tomate | Haussauce',
  },
  {
    title: 'twenty8 Burger',
    price: '',
    tags: '180 g Rib Eye Steak gegrillt | Rucola Salat | Tomate | karamellisierte rote Zwiebeln | Salat | Gurke | BBQ-Sauce',
  },
];

const veganAlternatives = [
  {
    title: 'Kidney Spears Burger',
    price: '',
    tags: 'homemade Kidney Bohnen Patty | Guacamole | Tomate | Gurke | rote Zwiebeln | Salat | Nachos | Special Sauce',
  },
  {
    title: 'Halloumi Nator BUrger',
    price: '',
    tags: 'Halloumi Grillkäse | Antipasti-Gemüse | Kräuter-Öl | Salat | Gurke | rote Zwiebeln | homemade Tomaten-Paprika-Salsa-Sauce',
  },
  {
    title: 'Jack Sparrow Burger',
    price: '',
    tags: 'Pulled Jack Fruit | Coleslaw | Salat | Tomate | Gurke | rote Zwiebeln | BBQ-Sauce',
  },
];

const toppings = [
  {
    title: 'Extra Beef Patty',
    price: '',
    tags: '',
  },
  {
    title: 'Cheddar',
    price: '',
    tags: '',
  },
  {
    title: 'Turkey Bacon',
    price: '',
    tags: '',
  },
  {
    title: 'Bio-Ei',
    price: '',
    tags: '',
  },
  {
    title: 'Karamellisierte Zwiebeln',
    price: '',
    tags: '',
  },
  {
    title: 'Jalapeños',
    price: '',
    tags: '',
  },
  {
    title: 'Antipaste-Gemüse',
    price: '',
    tags: '',
  },
];

const sideDishes = [
  {
    title: 'Pommes',
    price: '',
    tags: '',
  },
  {
    title: 'Curly Fries',
    price: '',
    tags: '',
  },
  {
    title: 'Süßkartoffel Pommes',
    price: '',
    tags: '',
  },
  {
    title: 'Onion Rings',
    price: '',
    tags: '',
  },
  {
    title: 'Coleslaw',
    price: '',
    tags: '',
  },
  {
    title: 'Chili Cheese Fries',
    price: '',
    tags: '',
  },
  {
    title: 'Nachos',
    price: '',
    tags: '',
  },
  {
    title: 'Beilagensalat',
    price: '',
    tags: '',
  },
];

const saucesAndDips = [
  {
    title: 'Sour Creme',
    price: '',
    tags: '',
  },
  {
    title: 'Guacamole',
    price: '',
    tags: 'vegan',
  },
  {
    title: 'Chili Cheese-Sauce',
    price: '',
    tags: 'spicy',
  },
  {
    title: 'Cheese-Sauce',
    price: '',
    tags: '',
  },
  {
    title: 'BBQ-Sauce',
    price: '',
    tags: '',
  },
  {
    title: 'Haussauce',
    price: '',
    tags: '',
  },
  {
    title: 'Tomaten-Paprika-Salsa',
    price: '',
    tags: 'vegan',
  },
  {
    title: 'Mayo',
    price: '',
    tags: 'vegan',
  },
];

const salads = [
  {
    title: 'Großer gemischter Salat',
    price: '',
    tags: '',
  },
  {
    title: 'Salat mit Crispy Chicken',
    price: '',
    tags: '',
  },
  {
    title: 'Rib Eye Steak 300g (mit einer Beilage)',
    price: '',
    tags: '',
  },
];

const thirstQuencher = [
  {
    title: 'Coca Cola',
    price: '',
    tags: '0,33 l',
  },
  {
    title: 'Coca Cola zero',
    price: '',
    tags: '0,33 l',
  },
  {
    title: 'Fanta-Orange',
    price: '',
    tags: '0,33 l',
  },
  {
    title: 'Sprite',
    price: '',
    tags: '0,33 l',
  },
  {
    title: 'Paulaner Spezi',
    price: '',
    tags: '0,5 l',
  },
  {
    title: 'Wasser medium',
    price: '',
    tags: '0,5 l',
  },
  {
    title: 'Wasser still',
    price: '',
    tags: '0,5 l',
  },
  {
    title: 'Apfelsaft-Schorle (naturtrüb)',
    price: '',
    tags: '0,5 l',
  },
  { 
    title: 'Gartenlimonade',
    price: '',
    tags: '0,5 l',
  },
  {
    title: 'Radler',
    price: '',
    tags: '0,5 l',
  }
];

const homemade = [
  {
    title: 'Limonade',
    price: '',
    tags: 'Mango-Maracuja | Grapefruit | Holunder | 0,4 l',
  },
  {
    title: 'Eistee',
    price: '',
    tags: '0,4 l',
  },
];

const beer = [
  {
    title: 'Grüner Hell',
    price: '',
    tags: '0,5 l',
  },
  {
    title: 'Grüner Radler',
    price: '',
    tags: '0,5 l',
  },
];

const sparkling = [
  {
    title: 'Aperol Spritz',
    price: '',
    tags: '0,2 l',
  },
];

const dessert = [
  {
    title: 'Schokoladen-Törtchen',
    price: '',
    tags: 'mit flüssigem Kern und Vanille-Eis',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Wir verwenden nur die besten Zutaten',
    subtitle: 'Für uns ist Qualität oberstes Gebot. Wir suchen ständig nach den besten Zutaten, um sicherzustellen, dass unsere Burger immer frisch und lecker sind.',
  },
  {
    imgUrl: images.award01,
    title: 'Wir glauben an Vielfalt',
    subtitle: 'Wir verstehen, dass jeder Gast unterschiedliche Geschmäcker hat. Deshalb bieten wir eine große Auswahl an Burgern an, von klassisch bis innovativ. Wir möchten, dass jeder Gast seinen perfekten Burger bei uns findet.',
  },
  {
    imgUrl: images.award05,
    title: 'Wir kümmern uns um unsere Gemeinschaft',
    subtitle: 'Wir möchten nicht nur großartige Burger anbieten, sondern auch unserer Gemeinschaft etwas zurückgeben. Wir arbeiten mit lokalen Lieferanten zusammen, um unsere Zutaten zu beziehen, und engagieren uns in verschiedenen gemeinnützigen Initiativen.',
  },
  {
    imgUrl: images.award03,
    title: 'Wir möchten, dass jeder Gast sich willkommen fühlt',
    subtitle: 'Bei uns geht es nicht nur um großartiges Essen, sondern auch um eine großartige Erfahrung. Wir möchten, dass jeder Gast sich bei uns willkommen fühlt, ob er alleine, mit Freunden oder mit der Familie kommt. Unsere freundlichen Mitarbeiter sorgen dafür, dass jeder Gast ein Lächeln im Gesicht hat, wenn er unseren Laden verlässt.',
  },
];

const data = { thirstQuencher, homemade, awards, burgers, veganAlternatives, toppings, sideDishes, saucesAndDips, salads, beer, sparkling, dessert };

export default data;
