import React, { useEffect, useState } from 'react';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';
import './Gallery.css';
import { galleryImages } from '../../constants/images';

const Gallery = () => {
  const scrollRef = React.useRef(null);
  const galleryRef = React.useRef(null);
  const [hasAnimatedGallery, setHasAnimatedGallery] = useState(false);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 0;
            entry.target.style.animation = "fadeIn 2s ease-in-out forwards";
            entry.target.addEventListener("animationstart", () => {
              entry.target.style.opacity = 0;
            });
            entry.target.addEventListener("animationend", () => {
              entry.target.style.opacity = 1;
            });
            if (entry.target === galleryRef.current) {
              setHasAnimatedGallery(true);
            }
          } else if (!hasAnimatedGallery && entry.target === galleryRef.current) {
            entry.target.style.animation = "none";
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const galleryNode = galleryRef.current;

    if (galleryNode) {
      observer.observe(galleryNode);
    }

    return () => {
      if (galleryNode) {
        observer.unobserve(galleryNode);
      }
    };
  }, [hasAnimatedGallery]);

  return (
    <div className="app__gallery flex__center" ref={galleryRef}>
      <div className="app__gallery-content">
        <SubHeading title="Instagram" />
        <h1 className="headtext__cormorant">Unsere Gallerie</h1>
        <a href="https://www.instagram.com/twenty8burger" target="_blank" rel="noopener noreferrer">
          <button type="button" className="custom__button">Besuche uns</button>
        </a>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {galleryImages.map((image, index) => (
            <div className="app__gallery-images_card flex__center" key={`gallery_image-${index + 1}`}>
              <img src={image} alt="gallery_image" />
              <a href="https://www.instagram.com/twenty8burger" target="_blank" rel="noopener noreferrer" className="gallery__image-link">
                <BsInstagram className="gallery__image-icon" />
              </a>
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort className="gallery__arrow-icon" onClick={() => scroll('left')} />
          <BsArrowRightShort className="gallery__arrow-icon" onClick={() => scroll('right')} />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
