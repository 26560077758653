import React, { useEffect } from 'react';
import './Impressum.css';

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="impressum-page p__opensans">
      <h1>Impressum</h1>
      <h2>Angaben gemäß § 5 TMG</h2>
      <p>Ibrahim Vuray</p>
      <p>Twenty8 Burger</p>
      <p>Bandelstr. 28</p>
      <p>91522 Ansbach</p>

      <h2>Kontakt:</h2>
      <p>Telefon: 0981 97770515</p>
      <p>E-Mail: info@twenty8burger.de</p>

      <h2>Gewerbeanmeldung</h2>
      <p>Die Gewerbeanmeldung nach §14 oder §55c GewO wurde am 25.11.2022 durch Stadt Ansbach erteilt.</p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
        <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.
        Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  );
};

export default Impressum;
