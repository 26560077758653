import bg from '../assets/background.jpg';
import gallery01 from '../assets/gallery01.jpg';
import gallery02 from '../assets/gallery02.jpg';
import gallery03 from '../assets/gallery03.jpg';
import gallery04 from '../assets/gallery04.jpg';
import gallery05 from '../assets/gallery05.jpg';
import gallery06 from '../assets/gallery06.jpg';
import quote from '../assets/quote.png';
import signature from '../assets/signature.png';
import chefi from '../assets/chef2.jpg';
import burger from '../assets/burger.png';
import nice from '../assets/nice.jpg';
import logo from '../assets/logo05.png';
import spatula from '../assets/spatula.png';
import cocktails from '../assets/cocktails.jpg';
import topping from '../assets/menu_topping.jpg';
import menuburger from '../assets/menu_burger.jpg';
import header from '../assets/header.jpg';
import contact from '../assets/contact.jpg';

const images = {
  bg,
  quote,
  signature,
  chefi,
  burger,
  nice,
  logo,
  spatula,
  cocktails,
  topping,
  menuburger,
  header,
  contact
};

const galleryImages = [
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
]

export { images, galleryImages };
